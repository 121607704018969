import React from 'react'
import { graphql } from 'gatsby'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Container } from 'theme-ui'

import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Banner from '@solid-ui-blocks/Hero/Block03'
import Content from '@solid-ui-blocks/Content/Block02'
import CallToAction from '@solid-ui-blocks/CallToAction/Block02'
import WhyChooseUs from '@solid-ui-blocks/Features/Block06'
import Faq from '@solid-ui-blocks/Faq/Block02'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '../_theme'

const imageFileNames = [
  'grind-and-seal-1.jpg',
  'grind-and-seal-2.jpg',
  'grind-and-seal-3.jpg',
  'grind-and-seal-4.jpg',
  'grind-and-seal-6.jpg',
  'grind-and-seal-7.jpg',
  'grind-and-seal-8.jpg',
  'grind-and-seal-9.jpg',
  'grind-and-seal-10.jpg',
  'grind-and-seal-11.jpg'
]

const images = imageFileNames.map(i => ({
  original: `/gallery/${i}`,
  thumbnail: `/gallery-thumbnail/${i}`
}))

const ServicesGrindSeal = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Concrete Sealing – Grind & Seal' />

      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='4' />

      <Banner content={content['banner']} />
      <Divider space='4' />

      <Content content={content['services']} />
      <Divider space='3' />

      <WhyChooseUs content={content['why-choose-us']} />
      <Divider space='3' />

      <CallToAction content={content['cta']} />
      <Divider space='3' />

      <Container variant='narrow'>
        <ImageGallery
          items={images}
          showPlayButton={false}
          thumbnailPosition='top'
        />
      </Container>
      <Divider space='3' />

      <Faq content={content['faq']} identifier='faq' />
      <Divider space='5' />

      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageServices01BlockContent {
    allBlockContent(
      filter: { page: { in: ["site/grind-and-seal", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default ServicesGrindSeal
